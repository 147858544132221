@import '../../assets/styles/setting';
body{
    .summary-wrapper {
        display: flex;
        flex-direction: column;
      }
      
      .view-more-toggle {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
        margin-top: 10px;
      }
      
      .asset {
        // display: flex;
        flex-direction: column;
        gap: 5px;
      }
      
    .container-fluid{
        .summary-invoice-container{
            padding: 20px;
            margin-left: 60px;   
            background-color: white;

            .inv-smr-list{
                .MuiTableCell-head{
                    background-color: #264061;
                    border: 1px solid #ebebeb;
                    color:#dfdfdf
                }
                .MuiTableCell-body{
                    border: 1px solid #c4c4c4;
                }

            }
            .receiavables-head{
                color:$color_9e4;
                font-size: 16px;
                font-family: Conv_IBMPlexSans-Bold;
                margin-bottom: 20px;
            }
            .sub-total-inv-smr{
                h5{
                    color: #9b9b9b;
                    font-size: 14px;
                    font-family: Conv_IBMPlexSans-Regular;
                }
                h4{
                    color: #090909;
                    font-size: 16px;
                    font-family: Conv_IBMPlexSans-Regular;
                }
            }
            .bank-details-head{
                h5{
                    margin-bottom: 20px;
                    font-family: Conv_IBMPlexSans-Bold;
                }
            }
            .bank-details-section-smr{
                padding: 20px;
                background-color: #f5f7fa;
                .MuiGrid-grid-md-3{
                    margin-bottom: 20px;
                }
            }
        }
        .from-lessor-card{
            background-color: #fafafa;
            .heading-block{
                padding: 15px;
                color:$color_9e4;
                font-size: 15px;
              }
              .lessee-lessor-data{
                padding: 10px 15px;
                h6{
                    margin-bottom: 10px;
                }
              }
        }
    }
}