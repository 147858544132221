@import '../../../../assets/styles/setting';
.monthly-cashflow-modal{
  max-width: 95%!important;
}
.utilization-information {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
}
.modal-container-low-utils{
  .modal-footer{
    padding: 7px 10px ;
  }
  h6{
    display: inline-block;
    font-weight: 600;
  }
}
.lnk-redrxn{
  color: #48a7cc;
  cursor: pointer;
  text-decoration: underline;
}
.contract-container{
  background: #fafafa;
  padding-bottom: 30px;
  height: 100vh;
  .contract-content-blk{
    padding: 0 15px;
    margin-left: 42px;
    .contract-header-info{
      padding-bottom: 30px;
      h3{
        font-family:$ibmplexRegular;
        font-size:20px;
        color:$color_000;
        margin-bottom:10px;
        margin-top: 10px;
        .return-cond-text{
          color: #3f51b5;
          padding-left: 16px;
          cursor:pointer;
        }
      }
      p{
        font-family:$ibmplexRegular;
        font-size:14px;
        color:#8b8d91;
      }
    }

    .list-container{
      min-height: 440px;
      .delete{
          font-family:$ibmplexRegular;
          font-size:12px;
          color:#dc3545;
          opacity: 0;
          &:hover{
           text-decoration: underline;
          }
      }
      .working-group-wrapper{
        background: $color_fff;
        border: 1px solid #c6cddd;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
        border-radius: 3px 3px 0 0;
        margin-bottom: 16px;
        .working-grp-title{
          font-family: $ibmplexRegular;
          font-size: 16px;
          color: #bd10e0;
          padding: 15px;
          p{
            display: inline;
            cursor: pointer;
          }
          ul{
            float: right;
            li{
              padding: 0 4px;
              img{
                width: 16px;
                cursor: pointer;
              }
              .wg-audit-history{
                color: #3f51b5;
                font-size: 12px;
                cursor: pointer;
                text-transform: uppercase;
              }
            }
          }
        }
        .table-container{
          margin-bottom: 0;
          border: none;
          box-shadow: none;
          border-radius: none;
          border-radius: none;
        }
      }
       .insert-inflation{
        color: #3f51b5;
        font-size: 14px;
        cursor: pointer;
        padding: 6px 8px;
        background: #3f51b5;
        color: $color_fff;
        border-radius: 3px;
      }

      .table-container{
        border: 1px solid #c6cddd;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
        border-radius: 3px 3px 0 0;
        position: relative;
         &.mr-inflation-table{
            margin-bottom: 0px;
            margin-top: 20px;
         }
        h5{
          font-family: $ibmplexRegular;
          font-size: 16px;
          color: #194886;
          padding: 15px;
          border-bottom: 1px solid #c6cddd;
          background: #ffffff;
          &.capeTown-edit{
            a{
            position: absolute;
            right: 15px;
            top: 14px;
            img{
              width: 16px;

            }
            }
          }
          span{
            float: right;
            cursor: pointer;
            img{
              width: 18px;
            }
            &.add{
              font-size: 12px;
              margin-left:5px;
            }
            &.in-audit-history{
              font-size: 12px;
              margin-left: 20px;
              position: relative;
              top: 2px;
              cursor: pointer;
            }
          }
        }
        table{
          width: 100%;
          background: #fff;
          thead{
            background: #fafafa;
            // background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
            // background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
            // background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
            // background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
            // background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
            // background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
            th{
              font-family:$ibmplexRegular;
              font-size:12px;
              color: #6d9bb9;
              padding: 15px;
            }
          }
          tbody{
            tr{
              cursor: pointer;
              transition:all 0.4s ease;
              &:nth-child(odd){
                background: #ebeff6;
              }
              &:nth-child(even){
                background: #f5f7fa;
              }
              &:hover{
                background: #D0D9E8;
                .delete{
                  opacity: 1;
                }
              }
              &.cp-convention{
                .convention-text{
                  width: 100%;
                  resize: none;
                    height: 50vh;
                    padding:8px;
                    &.readonly{
                      padding:0;
                      background: transparent
                    }
                }
                &:hover{
                  background: #ebeff6;
                }
              }
              td{
                padding: 15px;
                span{
                  font-family:$ibmplexRegular;
                  font-size:14px;
                  color: #000;
                }
              }

            }
          }
        }

        .captwn-submit-block{
          text-align: right;
          padding:15px 15px;

          a{
            font-size: 12px;
            font-family: $ibmplexRegular;
            color:$color_cff;
            margin-right:20px;
          }
        }
      }
      .security-deposit-wrapper{
        margin-bottom:15px;
        p{
          line-height: 16px;
          &.security-type{
            font-family:$ibmplexRegular;
            font-size:16px;
            color:#194886;
          }
        }
        span{
          font-family:$ibmplexRegular;
          font-size:14px;
          color:#000;
          .attachment-block{
            margin-right:4px;
          }
        }
        h6{
          font-family:$ibmplexRegular;
          font-size:12px;
          color:#8b8d91;
          &.transaction-heading{
            font-family: $ibmplexSemiBold;
            margin-bottom:20px;
            color:#bd10e0;
          }
          .add-trasaction{
            margin-left: 4px;
          }
        }
        .security-heading{
          border-bottom:1px solid #e4e4e4;
          margin:0;
          padding: 12px 15px;
          cursor: pointer;

          h6{
            margin-bottom:6px;
          }
        }

        .cash-non-cash-trxn-row{
          marign-bottom:10px;
          cursor: pointer;
          padding-top:10px;
          padding-bottom:10px;
        }

        .cash-non-cash-trxn-row:hover{
          background:#e4e4e4;
        }

        .security-element{
          background: rgba(255,255,255,0.07);
          background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
          background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
          background:#f5f7fa;
          border-radius:0 0 3px 3px;
          padding: 15px 12px;
          .transaction-wrapper{
            padding-bottom: 15px;
            border-bottom: 1px solid #e4e4e4;
            margin-bottom: 15px;
            &:last-child{
              margin-bottom:0;
              padding-bottom:15px;
              border-bottom: none;
            }
          }
        }
      }
    }
    .contract-headline-block{
      h6{
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#a0a0a0;
      }
      span{
        font-family:$ibmplexRegular;
        font-size:14px;
        color:#000000;
        line-height:20px;
      }
      h4{
        font-family:$ibmplexRegular;
        font-size:20px;
        color:#000000;
        margin-bottom:24px;
        margin-top:10px;
      }
      h5{
        font-family:$ibmplexRegular;
        font-size:16px;
        color:#9439e4;
        padding-bottom:15px;
        .editIcon{
          float:right;
          cursor: pointer;
        }
      }
      .card-block{
        margin-bottom:20px;
        background: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
        border-radius: 3px;
        padding: 25px 15px 15px;
      }
      .vertical-card{
        height:95%;
        ul{
          li{
            margin-bottom:20px;
            &.operator-same{
              margin-bottom:0;
              img{
                margin-right:5px;
              }
            }
          }
        }
      }
      .horizontal-card{
        .blue-img{
          cursor: pointer;
          float: right;
        }
        .horizontal-inner-block{
          margin-bottom: 20px;
        }
      }
    }
  }
  .contract-invoice-config{
    position: relative;
    background:linear-gradient(90deg, rgb(44, 69, 193), rgba(150, 95, 222, 0.93));
    border:1px solid #c6cddd;
    padding:15px 15px 0;
    .edit-card-icon{
      cursor: pointer;
      position: absolute;
      right: 15px;
    }
    h6{
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#f0a4ff;
    }
    span{
      font-family:$ibmplexRegular;
      font-size:14px;
      color:$color_fff !important;
      line-height:20px;
    }
    p{
      font-family:$ibmplexRegular;
      font-size:16px;
      color:$color_fff;
      margin-bottom:15px;
    }
    .invoice-inner-warp{
      margin-bottom:30px;
    }
    .invoice-last-block{
      background: rgba(255,255,255,0.3);
      padding: 15px 0;
      .invoice-inner-warp{
        margin-bottom:15px;
        &:nth-child(3n+1):nth-last-child(-n+3),
        &:nth-child(3n+1):nth-last-child(-n+3) ~ .invoice-inner-warp {
          margin-bottom:0;
        }
        p{
          font-size:14px;
          margin-bottom:0;
        }
      }
    }
  }




  .utilization-table-block{
    .uti-heading{
      padding: 15px;
    }

  }

  .cash-flow-wrapper{

    .uti-heading{
      position: relative;
      padding: 15px 0;
      a{
        cursor: pointer;
        font-family:$ibmplexRegular;
        color:$color_fff;
        padding:7px 15px;
        position: absolute;
        right: 0px;
        top:15px;
        &:hover{
          color:$color_fff;
        }
      }
    }
    .cashflow{
       padding:0;
    }
    .no-records-found{
      margin-left:45px;
      margin-right: 0px;
    }
   }
   .highter {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #FB5353;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(251,83,83,0.5);
    animation: pulse 2s infinite;
   }
  }
  .reviewFinanceRpt-blk{
    .cashflow {
      margin: 0 0 0 15px;

    }
  }





// Extra small devices (portrait phones, less than 576px)
@media (max-width: 991px) {
  .auditlog-btn{
    display: none !important;
  }
  .editIcon, .blue-img {
    display: none;
  }
  .add-new {
    display: none !important;

  }
  .wg-audit-history{
    display: none;
  }


  .sub-headder-fixed{
    display: none;
  }


// mr calculater scss
.mr-calc-sidebar{
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0 !important;
  bottom:0;
  opacity: 0;
  transition:visibility 0.3s linear,opacity 0.3s linear;
  visibility: hidden;
  z-index: 0;
  .mr-calc-heading{
    margin-top: -9px;
  }
  &.sidebar-active{
    opacity: 1;
    transition:visibility 0.3s linear,opacity 0.3s linear;
    visibility: visible;
    z-index: 999;
  }
  .mbl-cross{
    color: #000;
    margin: 10px 10px 10px auto;
    display: block;
    text-align: right;
    img{
      cursor: pointer;
      position: absolute;
      right: 9px;
      width: 25px;
    }
  }
  ul{
    margin-top: 45px;
  }
  &.contract-side-bar{
    opacity: 1;
    visibility: visible;
    height: 90px;
    z-index: 91;
    left: 60px;
    top: 135px !important;
    height: 48px;
    .mbl-cross{
      display: none;
    }
    ul{
      white-space: nowrap;
      overflow-x: auto;
      li{
        display: inline-block;
        a{
          padding: 15px 15px;
          &.active{
            text-decoration: underline;
          }
        }
        &:last-child{
          padding-right: 60px;
        }
      }
    }
  }
}

.sub-headder-info{
  #contract-header{
    top: 40px !important;
    display: block;
    left: 60px;
    >.col-sm-6{
      display: none;
      &.switcher-container{
        display: block;
        width: 100% !important;
        max-width: 100% !important;
        .left-side-info{
          .msn-switcher{
            li{
              &:last-child{
                display: block;
                margin-top: 8px;
                margin-left: 0 !important;
                width: 100%;
                li{
                  margin-top: 0;
                }
              }
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}
.contract-loader{
  height: 150px;
  left: 60px !important;
  vertical-align: middle;
  padding-top: 50px !important;
}


  .contract-container{
    margin-left: 60px;
    padding-top: 155px;
    .contract-content-blk{
      padding:0;
      .contract-header-info{
        a{
          display: block;
          text-align: center;
          margin-top: 10px;
        }
      }
      .list-container{
        .working-group-wrapper{
          .working-grp-title{
            overflow: hidden;
            p{
              display: inline-block;
              margin-bottom: 8px;
            }
          }
          .card{
            width: 100%;
          }
        }
        .table-container{
          overflow: auto;
          &::-webkit-scrollbar{
            display: none;
          }
        }
      }
    }
    .contract-db-wrapper{
      table{
        tr{
          table-layout: auto !important;
        }
      }
      .flagged-condition {
        .flagged-condition-wrapper{
          overflow-x: auto;
        }
      }
      .timeline-block{
        .timeline-inner-block{
          height: 250px;
        }
        .timeline-heading-block .nav-btn{
          top: 25px;
        }
      }
    }
    .utilization-table-block{
      .utilization-wrapper{
        overflow-x: auto;
        table{
          tr{
            table-layout: auto;
          }
        }
      }
    }
    .cash-flow-wrapper{
      .utilization-outer-wrapper{
        margin-left: 0 !important;
      }
      .mobile-asset-list{
        .utilization-history{
          margin-left: 0 !important;
        }
      }
    }
    .invoice-wrapper, .mr-rate-wrapper, .mr-graph-block, .hl-filter-block, .contract-list-mbl-spac{
      margin-left: 0 !important;
    }
    .mr-graph-block{
      margin-bottom: 50px;
      .card{
        display: block;
        margin: 0;
      }
      table{
        tr{
          table-layout: auto;
        }
      }
    }
    .half-life-maintenance{
      margin: 0;
    }
    .contract-list-mbl-spac{
      margin-top: 15px;
    }
    .log-listing-block .logList{
      white-space: normal;
    }

  }

  .contract-container{
    h3{
      .return-cond-text{
        font-size: 14px;
        }
    }
    .cash-flow-wrapper{
      margin:0 !important;
      .uti-heading{
        a{
          display:none;
        }
      }
      .mobile-asset-list{
        .cashflow{
          width: 200%;
        }
      }
    }
  .utilization-table-block{
    overflow:auto;
    }
    .add-trasaction{
      display: none;
    }
  }
  .mr-calculator-logs{
    padding-top: 0;
    margin: 0;
    margin-top: 0 !important;
    .logList{
      display: inline-block;
      white-space: nowrap !important;
      >div{
        display: inline-block;
        width: 150px;
      }
    }
  }
  .deployment-info-text{
    font-size: 10px;
    width: fit-content;
    display: block;
    background: #fff1b6;
    padding: 2px 5px;
    color: #a78701;
    border: 1px solid #a78701;
    border-radius: 4px;
  }
  .edit-header-side-bar{
    // width: 100% !important;
    .warning-info-text{
      margin: 0 10px !important;
      background: #a78701;
    }
    form{
      .form-group{
        margin-bottom: 15px;
      }
    }
  }
}

  .modal-body{
    .MuiChip-deletable{
      background-color: #f3f3f3;
      height:auto !important;
      span{
        word-break: break-all !important;
        white-space: normal !important;
      }
    }
  }


.deployment-info-text{
  font-size: 12px;
  width: fit-content;
  display: block;
  margin-bottom: 6px;
  background: #fff1b6;
  padding: 2px 5px;
  color: #a78701;
  border: 1px solid #a78701;
  border-radius: 4px;
}
.drag-drop-cnt{
  .MuiDropzoneArea-textContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .MuiDropzoneArea-text{
      margin-top: auto;
    }
    .MuiDropzoneArea-icon{
      color: #3f51b5;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .contract-container{
    h3{
      .return-cond-text{
        font-size: 14px;
        }
    }
    .cash-flow-wrapper{
      margin:0 !important;
      .uti-heading{
        a{
          display:none;
        }
      }
    }
  // utilization scss
    .utilization-table-block{
    overflow:auto;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  // utilization scss


}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contract-container{
    margin-left:215px !important;
    margin-top: 90px;
   .utilization-table-block{
      margin: 0 0px 0 60px;
    }
     // utilization scss
    .util-heading-block{
      // margin: 0 15px 0 60px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .contract-container{
    margin-left:215px !important;
    margin-top: 70px;
    .contract-header-info{
      .add-new{
        float: right;
      }
      .auditlog-btn{
        margin-right:15px;
        float: right;
      }
      h3{
        .return-cond-text{
           font-size: 16px;
         }
      }
    }

    .utilization-table-block{
      margin: 0 0px 0 60px;
    }
    // utilization scss
    .util-heading-block{
      // margin: 0 15px 0 60px;
    }
  }
}
