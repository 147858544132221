@import '/setting';
body{
  .MuiAutocomplete-input{
    height: auto !important;
  }
  .list-graph-sw{
    display: inline-block;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    line-height: 0;
    span{
      height: 30px;
      width: 40px;
      display: inline-block;
      cursor: pointer;
      position: relative;
      &:first-child{
        border-right: 1px solid #d7d7d7;
      }
      svg{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .contract-container{
    .contract-content-blk{
      .apps-title-cta{
        h4{
          font-family: $ibmplexRegular;
          font-size: 20px;
          color: #000;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        p{
          font-family: $ibmplexRegular;
          font-size: 14px;
          color: #8b8d91;
        }
        a{
          font-family: $ibmplexRegular;
          font-size: 15px;
          line-height: 25px;
          color:rgb(47, 140, 254);
          text-decoration: underline;
        }
      }
      .apps-title-cta-list{
        float: right;
        ul{
          li{
            .MuiButton-root{
              font-family: $ibmplexRegular;
              padding: 4px 15px;
            }
            &.revision-history{
              margin-right: 15px;
              .rev-history-title{
                font-family: $ibmplexSemiBold;
                font-size: 14px;
                color: #3f51b5;
                text-transform: uppercase;
                cursor: pointer;
                .audit-img{
                  width: 18px;
                  margin-right: 5px;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
      .return-cond-text{
        font-size: 16px;
        color: #3f51b5;
        cursor: pointer;
      }
      .lease-details-tabs, .utils-trend-card, .mr-rental-ulilization-tabs{
        clear: both;
        margin-bottom: 20px;
        .MuiTab-root{
          font-size: 13px!important;
          .MuiTab-wrapper{
            font-family: $ibmplexRegular;
            color:rgba(0, 0, 0, 0.54);
            font-size: 13px!important;
          }
        }
        .PrivateTabIndicator-root-2{
          width: 115px !important;
        }
        .MuiTabs-scroller {
          border-bottom: 1px solid #d7d7d7;
          margin-bottom: 20px;
      }
      }
      .utils-trend-head{
        font-family: $ibmplexRegular;
        font-size: 16px;
        color: #bd10e0;
        margin-bottom: 15px;
      }
      .utils-trend-card{
        margin-bottom: 40px;
      }
      .mui-table{
        position: relative;
        tr{
          &.row-head{
            position: absolute;
            left: 62%;
            top: 5px;
            z-index: 9;
            .row-title{
              font-family: $ibmplexSemiBold;
              font-size: 13px;
              color: #6d9bb9;
            }
          }
          th{
            padding-top: 35px;
          }
        }
      }
    }
    .contracts-wrapper-section{
      .status-label{
        width: 100px;
        text-align: center;
        display: inline-block;
        text-transform: uppercase;
        border-radius: 4px;
        padding: 5px 0;
        font-size: 12px;
        font-family: $ibmplexSemiBold;
        .fa{
          font-size: 16px;
          vertical-align: text-bottom;
          margin-left: 3px;
        }
        &.green{
          background-image: linear-gradient(90deg,#beed87,#d2e9b6 95%);
          color: #5ec264;
        }
        &.blue{
          background: #89bfff;
          color: #1a3b62;
          border: 1px solid #459bff;
        }
        &.status-3{
          background-image:linear-gradient(269deg, #a7c4ff 0%, #729cf1 100%);
          color:#4c79d5;
        }
        &.status-1{
          background-image: linear-gradient(90deg,#beed87,#d2e9b6 95%);
          color: #5ec264;
        }
        &.status-2{
          background-image: linear-gradient(269deg,#ffdfcb,#ffb98d 95%);
          color: #ee7c26;
        }
        &.status-0{
          background: #89BFFF;
          color: #1A3B62;
        }
        &.status-4{
          background: linear-gradient(180deg,#d7d7d7 0,#f6f6f6 95%,#fff);
          color: #333;
        }
        &.status-16{
          background-image: linear-gradient(269deg,#ffdfcb,#c0a696 95%);
          color: #734e31;
        }
        &.status-17{
          background-image: linear-gradient(90deg,#e8b8ce,#ee86d1 95%);
          color: #461939;;
        }
        &.status-14{
          background-image:linear-gradient(269deg, #a7c4ff 0%, #3a66be 100%);
          color:#244382;
        }
        &.status-15{
          background-image:linear-gradient(269deg, #a08ad1 0%, #7450bb 100%);
          color:#250f30;
        }
      }
      .cape-town-convention{
        .cape-town-convention-header{
          font-family: $ibmplexRegular;
          font-size: 16px;
          color: #194886;
          border: 1px solid rgba(224, 224, 224, 1);
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
          background: #fafafa;
          padding: 16px;
          .icon-blk{
            float: right;
            svg{
              cursor: pointer;
              font-size: 20px;
            }
          }
        }
        .cta-blk{
          float: right;
          margin-top: 10px;
        }
      }
      .mui-styled-table{
        tr{
          &.jac-parent-header{
            th{
              line-height: 0;
              padding: 15px 0px 5px;
              border-bottom: none;
            }
          }
          th{
            background: #f0f2f3;
            color: #6d9bb9;
            font-size: 13px;
            font-family: $ibmplexSemiBold;
          }
        }
      }
    }
    .mr-calc-sidebar{
      &.contract-side-bar{
        padding-top: 30px;
      }
    }
  }
  .security-deposit-list{
    margin-bottom: 30px;
    border: 1px solid #c6cddd;
    .security-deposit-hd{
      background: #ffffff;
      padding: 10px;
      border-bottom: 1px solid #c6cddd;
      position: relative;
      h4{
        margin-bottom: 4px;
        @include elementStyles($size: 16px, $color: #194886, $fontFamily: $ibmplexRegular);
      }
      h6{
        @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
      }
      p{
        margin-bottom: 4px;
        @include elementStyles($size: 12px, $color: #8b8d91, $fontFamily: $ibmplexRegular);
      }
    }
    h5{
      @include elementStyles($size: 14px, $color: #bd10e0, $fontFamily: $ibmplexSemiBold);
      padding: 10px;
      svg{
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }
  .contract-view-card{
    &.lease-card{
      margin-top: 40px;
    }
    .card-title{
      margin-bottom: 16px;
      h4{
        @include elementStyles($size: 16px, $color: #9439e4, $fontFamily: $ibmplexRegular);
      }
      span{
        cursor: pointer;
        margin-left: auto;
      }
    }
    p{
      @include elementStyles($size: 14px, $color: #a0a0a0, $fontFamily: $ibmplexRegular);
      margin-bottom: 6px;
    }
    h6{
      @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular);
    }
  }

  .fleet-lease-management-sec{
        margin-left: 70px;
        margin-top: 10px;
        .mui-styled-table{
          tbody{
            .MuiTableCell-root {
              padding: 9px !important;
            }
          tr{
            td{
              font-family: $ibmplexRegular!important;
              font-size: 13px!important;
              width:150px;
              &.asset-info{
                width: 300px;
                .asset-wrapper{
                  .folder-icon{
                    width: 30px;
                  }
                  .asset{
                    margin-left: 10px;
                    text-align: left;
                    p{
                      font-family: Roboto, Helvetica, Arial, sans-serif;
                      font-size: 11px;
                      font-weight: 300;
                      width:150px
                    }
                    h6{
                      font-family: $ibmplexSemiBold;
                      font-size: 13px;
                      font-weight: 700
                    }
                  }
                }
              }
              .payment-text{
                font-size: 10px;
                font-family: "Conv_IBMPlexSans-SemiBold";
                display: block;
                margin-top: 4px;
              .invoice-status-payment{
                display: block;
                color: #ff0000;
              }
            }
              .status{
                font-size: 13px;
                padding: 4px;
                border-radius: 6px;
                text-align: center;
                width: 120px;
              }
            }
          }
        }
      }
    .top-head-cta{
      ul{
        li{
          .fleet-cta{
            position: relative;
            border:1px solid #a19f9deb;
            border-radius:4px;
            li{
              cursor: pointer;
              padding:0px 8px;
              margin:auto;
              background:white;
              &.list-view-li{
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
              }
              &.graph-view-li{
                background: #e0e0e0eb;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
              }
              .list-icon{
                padding-top: 6px;
              }
              .pie-graph-icon{
                color:#6b6565;
                width: 20px;
                padding-top: 6px;
              }
            }
          }
        }
      }
    }
    .fleet-lease-analytics-sec{
      margin-bottom: 50px;
      .graph-head-left-blk{
        h4{
          @include elementStyles($size: 16px, $color: #bd10e0, $fontFamily: $ibmplexSemiBold);
          margin-bottom: 3px;
        }
        p{
          @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
          margin-bottom: 5px;
        }
      }
      .fleet-cta{
        position: relative;
        border:1px solid #a19f9deb;
        border-radius:4px;
        float: right;
        bottom: 32px;
        li{
          cursor: pointer;
          padding:0px 8px;
          margin:auto;
          background:white;
          &.list-view-li{
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
          &.graph-view-li{
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
          .list-icon{
            padding-top: 6px;
          }
          .pie-graph-icon{
            // color:#6b6565;
            width: 20px;
            padding-top: 6px;
          }
        }
      }
      .makeStyles-table{
        min-width: 500px!important;
      }
      .MuiDivider-root{
        background-color: #e6e6e6;
        opacity: 0.5;
      }
      .plt-filter-comp{
        .filter-applied{
          padding-right: 50px;
          ul{
            li{
              font-size: 10px;
              margin: 0 3px 3px 0;
              padding: 2px 5px 4px 8px;
              .filter-label{
                margin-right: 5px;
              }
              .remove-filter{
                top: 1px;
                svg{
                  font-size: 14px;
                }
              }
            }
          }
        }
        .clear-all-filter{
          font-size: 10px;
        }
      }
      .select-block{
        padding: 5px 10px 5px;
        border-radius: 0px;
        align-items: center;
        p{
          &.asset-title{
            @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
            position: relative;
            top: 8px;
          }
        }
        .Select-control{
          font-size: 13px;
        }
      }
      .generic-filter-block{
        margin-bottom: 10px;
        padding: 8px 5px;
        ul{
          li{
            .MuiFormLabel-root{
              line-height: 0;
            }
            .MuiOutlinedInput-root{
              padding: 0;
            }
            .MuiButton-root{
              .MuiButton-label{
                font-size: 14px;
                font-family: $ibmplexRegular;
              }
            }
            .MuiAutocomplete-paper{
              font-size: 13px!important;
              font-family: $ibmplexRegular!important;
            }
            .MuiInputLabel-outlined.MuiInputLabel-shrink {
              transform: translate(14px, 0px) scale(0.75);
          }
          label{
            @include elementStyles($size: 14px, $color: #5e5c5c, $fontFamily: $ibmplexMedium, $weight:500);
        }
            input[type="text"]{
              height: auto;
              &::placeholder {
                font-size: 13px;
                font-family: $ibmplexRegular;
                color: #000;
              }
            }
          }
        }
      }
    }
  }

  .lease-management-section{
    padding-bottom: 30px;
    margin-right: -15px;
    margin-left: 45px;
    &.lease-statistics-section{
      h2{
        @include elementStyles($size: 18px, $color: #bd10e0, $fontFamily: $ibmplexMedium);
        span{
          @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
        }
      }
      .cashflow-summary-section{
        padding: 10px;
        margin-bottom: 15px;
        .cashflow-db-widget{
          table{
            width: 100%;
            p{
              @include elementStyles($size: 15px, $color: #000000, $fontFamily: $ibmplexRegular, $margin:0 0 2px 0);
            }
            h4{
              @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexMedium);
            }
            span.date{
              @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
            }
          }
        }
      }
      .invoice-summary{
        .widget-amount{
          padding: 15px;
          p{
            @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
          }
          h4{
            @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular, $margin:4px 0 0 0);
          }
        }
        h3{
          @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexMedium, $margin:0 0 0 0);
          background: #ededed;
          padding: 6px 15px;
          clear: both;
          button{
            margin-left: auto;
          }
        }
        .invoice-list{
          clear: both;
          max-height: 300px;
          overflow: auto;
          table{
            thead,th{position: static;}
          }
        }
      }
      .avergare-utils-section{
        .avergare-utils-card{
          background: linear-gradient(90deg, #2c45c1, rgba(150, 95, 222, 0.93));
          border-radius: 1px;
          padding: 15px;
          height: 100%;
          margin-right: 15px;

          h3{
            @include elementStyles($size: 18px, $color: #ffffff, $fontFamily: $ibmplexMedium, $margin: 0 0 10px 0);
          }
          p{
            @include elementStyles($size: 15px, $color: #ffffff, $fontFamily: $ibmplexRegular, $margin: 0 0 5px 0);
            text-align: center;
          }
          h5{
            @include elementStyles($size: 15px, $color: #ffffff, $fontFamily: $ibmplexMedium, $margin: 0 0 10px 0);
            text-align: center;
          }
        }
      }
    }
    .fund-acc-card{
      padding: 15px;
      margin-bottom: 15px;
      h3{
        @include elementStyles($size: 18px, $color: #bd10e0, $fontFamily: $ibmplexMedium, $margin: 0 0 10px 0);
      }
    }
    .reserve-account-section{
      .reserve-account-card{
        background: linear-gradient(90deg, #2c45c1, rgba(150, 95, 222, 0.93));
        border-radius: 1px;
        padding: 15px;
        height: 100%;
        h3{
          @include elementStyles($size: 16px, $color: #ffffff, $fontFamily: $ibmplexMedium, $margin: 0 0 10px 0);
        }
        h5,p{
          @include elementStyles($size: 15px, $color: #ffffff, $fontFamily: $ibmplexRegular);
        }
        h6{
          @include elementStyles($size: 15px, $color: #ffffff, $fontFamily: $ibmplexMedium, $margin: 4px 0 0 0);
        }
        span.date{
          @include elementStyles($size: 13px, $color: #b6b6b6, $fontFamily: $ibmplexRegular);
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
  }
  .invoice-detail-section{
    padding-bottom: 30px;
    margin-right: -15px;
    margin-left: 45px;
    .back-invoice-view{
      margin-bottom: 15px;
      p{
        @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
      }
      h3{
        @include elementStyles($size: 20px, $color: #000000, $fontFamily: $ibmplexSemiBold);
      }
      ul.list-inline{
        float: right;
        li{
          font-family: $ibmplexSemiBold;
          color: #3f51b5;
          margin-left: 15px;
          cursor: pointer;
          a{color: #3f51b5}
        }
      }
    }
    .invoice-basic-detail{
      padding: 15px;
      margin-bottom: 15px;
    }
    .lessor-lessee-info{
      padding: 15px;
      h4{
        @include elementStyles($size: 18px, $color: #9439e4, $fontFamily: $ibmplexMedium, $margin: 0 0 15px 0);
      }
    }
    table{
      thead, th{
        background: #264061;
        span{
          color: #ffffff !important;
        }
      }
    }
    .invoice-gross-total{
      float: right;
      margin: 5px 0 20px 0;
      h5,h4{
        text-align: right;
        @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 5px 0);
      }
      h4{
        font-family: $ibmplexMedium;
      }
    }
    .invoice-bank-detail{
      clear: both;
      padding: 15px;
      h4{
        @include elementStyles($size: 18px, $color: #9439e4, $fontFamily: $ibmplexMedium, $margin: 0 0 15px 0);
      }
    }
  }
  .delete-record-modal{
    text-align: center;
    padding: 30px;
    h4{
      @include elementStyles($size: 20px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 40px 0);
    }
    .actions-cta{
      button{
        margin-left: 10px;
        &.first-child{
          margin-left: 0;
        }
      }
    }
  }
  .mui-styled-table{
    tbody{
      .MuiTableCell-root {
        padding: 9px !important;
      }
      tr{
        td{
          font-family: $ibmplexRegular!important;
          font-size: 13px!important;
          width:150px;
          &.asset-info{
            width: 220px;
            .asset-wrapper{
              .folder-icon{
                width: 30px;
              }
              .asset{
                margin-left: 10px;
                text-align: left;
                p{
                  font-family: Roboto, Helvetica, Arial, sans-serif;
                  font-size: 11px;
                  font-weight: 300;
                  width:150px
                }
                h6{
                  font-family: $ibmplexSemiBold;
                  font-size: 13px;
                  font-weight: 700
                }
              }
            }
          }
          .payment-text{
            font-size: 10px;
            font-family: "Conv_IBMPlexSans-SemiBold";
            display: block;
            margin-top: 4px;
          .invoice-status-payment{
            display: block;
            color: #ff0000;
          }
        }
          .status{
            font-size: 13px;
            padding: 4px;
            border-radius: 6px;
            text-align: center;
            width: 120px;
          }
        }
      }
    }
  }
  .underlying-data-title{
    padding: 16px 8px;
  }

  // New Filter UI Components
  .filter-ui-drawer{
    .filter-ui-drawer-header, .filter-ui-drawer-body, .filter-ui-drawer-footer{
        padding: 10px 15px;
    }
    .filter-ui-drawer-header{
        border-bottom: 1px solid #D7D7D7;
        h4{
            @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .filter-ui-drawer-body{
        overflow: auto;
        input[type='text'], textarea, input[type='number']{
          height: auto !important;
        }
    }
    .filter-ui-drawer-footer{
        border-top: 1px solid #D7D7D7;
    }
  }
}
